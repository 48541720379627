import React, { useState } from 'react';
import axios from 'axios';
import '../css/bouton.css';

const GrafanaConfigForm = ({ formData, setFormData, handleChange, setMessage }) => {

  const [showImportGrafana, setShowImportGrafana] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const handleImportOrgName = async () => {
    const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = formData;

    console.log("urlGrafana:", urlGrafana);
    console.log("orgIdGrafana:", orgIdGrafana);

    if (!urlGrafana || !grafanaUsername || !grafanaPassword) {
      setMessage("Veuillez remplir l'URL Grafana, le nom d'utilisateur et le mot de passe.");
      return;
    }

    try {
      const urlObj = new URL(urlGrafana);
      console.log("urlObj:", urlObj);

      // Construire baseGrafanaUrl en utilisant l'URL sans les paramètres de requête
      const baseGrafanaUrl = urlObj.origin + urlObj.pathname;
      console.log("baseGrafanaUrl:", baseGrafanaUrl);

      // Utiliser orgIdGrafana s'il est présent, sinon extraire des paramètres
      let orgId = formData.orgIdGrafana || urlObj.searchParams.get('orgId');
      console.log("orgId initial:", orgId);

      if (!orgId) {
        setMessage("L'orgId n'est pas spécifié dans l'URL ou le champ OrgID.");
        return;
      }

      // Mise à jour du formulaire si orgId a été extrait
      if (!formData.orgIdGrafana && orgId) {
        setFormData({ ...formData, orgIdGrafana: orgId });
      }

      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      console.log("apiBaseUrl:", apiBaseUrl);

      // Envoi du nom d'utilisateur et du mot de passe Grafana au backend
      const response = await axios.get(`${apiBaseUrl}/proxy/grafana/orgs/${orgId}`, {
        params: { baseGrafanaUrl, grafanaUsername, grafanaPassword }
      });
      console.log("Réponse du backend:", response.data);

      if (response.data.error === "Unauthorized") {
        setMessage("Erreur : Identifiant ou mot de passe incorrect pour Grafana.");
      } else {
        const orgName = response.data.name;
        console.log("Nom de l'organisation récupéré:", orgName);
        setFormData({ ...formData, NomDuProjet: orgName });
        setMessage("Nom de l'organisation importé avec succès.");
      }
    } catch (error) {
      console.error("Erreur lors de l'importation du nom de l'organisation:", error);
      setMessage("Erreur lors de l'importation du nom de l'organisation.");
    }
  };

  const handleValidateUrl = async () => {
    const { urlGrafana, orgIdGrafana, grafanaUsername, grafanaPassword } = formData;

    console.log("urlGrafana:", urlGrafana);
    console.log("orgIdGrafana:", orgIdGrafana);

    if (!urlGrafana || !grafanaUsername || !grafanaPassword) {
      setMessage("Veuillez remplir l'URL Grafana, le nom d'utilisateur et le mot de passe.");
      return;
    }

    try {
      const urlObj = new URL(urlGrafana);
      console.log("urlObj:", urlObj);

      // Construire baseGrafanaUrl en utilisant l'URL sans les paramètres de requête
      const baseGrafanaUrl = urlObj.origin + urlObj.pathname;
      console.log("baseGrafanaUrl:", baseGrafanaUrl);

      // Utiliser orgIdGrafana s'il est présent, sinon extraire des paramètres
      let orgId = formData.orgIdGrafana || urlObj.searchParams.get('orgId');
      console.log("orgId initial:", orgId);

      if (!orgId) {
        setMessage("L'orgId n'est pas spécifié dans l'URL ou le champ OrgID.");
        return;
      }

      // Mise à jour du formulaire si orgId a été extrait
      if (!formData.orgIdGrafana && orgId) {
        setFormData({ ...formData, orgIdGrafana: orgId });
      }

      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      console.log("apiBaseUrl:", apiBaseUrl);

      // Envoi du nom d'utilisateur et du mot de passe Grafana au backend
      const response = await axios.get(`${apiBaseUrl}/proxy/grafana/orgs/${orgId}`, {
        params: { baseGrafanaUrl, grafanaUsername, grafanaPassword }
      });
      console.log("Réponse du backend:", response.data);

      if (response.data.error === "Unauthorized") {
        setMessage("Erreur : Identifiant ou mot de passe incorrect pour Grafana.");
      } else {
        console.log("L'URL Grafana est valide et accessible.");
        setMessage("L'URL Grafana est valide et accessible.");
        // Ne pas modifier le champ NomDuProjet ici
      }
    } catch (error) {
      console.error("Erreur lors de la validation de l'URL Grafana:", error);
      setMessage("Erreur lors de la validation de l'URL Grafana.");
    }
  };

  const isValidateUrlDisabled = !formData.urlGrafana || !formData.orgIdGrafana || !formData.grafanaUsername || !formData.grafanaPassword;

  return (
    <>
      <div className="space-y-0 border rounded-md">
        <div
          className="p-4 rounded-md  cursor-pointer"
          onClick={() => setShowImportGrafana(!showImportGrafana)}
        >
          <h3 className="text-md font-semibold text-black flex items-center">
            <i className={`fas fa-chevron-${showImportGrafana ? 'up' : 'down'} w-4 h-4 mr-2`}></i>
            Import Grafana
          </h3>
        </div>
        {showImportGrafana && (
          <div className="p-4 bg-gray-100 space-y-4 ">
            <div>
              <label className="block text-sm font-medium text-gray-700 ">URL Grafana</label>
              <input
                type="text"
                name="urlGrafana"
                value={formData.urlGrafana}
                onChange={handleChange}
                placeholder="https://example.com/grafana/"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm border-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">OrgID</label>
              <input
                type="text"
                name="orgIdGrafana"
                value={formData.orgIdGrafana}
                onChange={handleChange}
                placeholder="Entrez l'OrgID manuellement"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm border-2"
              />
            </div>
            {/* Champs pour le nom d'utilisateur et le mot de passe Grafana */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Nom d'utilisateur Grafana</label>
              <input
                type="text"
                name="grafanaUsername"
                value={formData.grafanaUsername}
                onChange={handleChange}
                placeholder="Entrez le nom d'utilisateur Grafana"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm border-2"
              />
            </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Mot de passe Grafana</label>
                    <div className="relative">
                        <input
                        type={showPassword ? 'text' : 'password'}
                        name="grafanaPassword"
                        value={formData.grafanaPassword}
                        onChange={handleChange}
                        placeholder={showPassword ? "Le mot de passe est visible" : "Entrez le mot de passe Grafana"}
                        className="mt-1 block w-full px-3 py-2 pr-10 border border-gray-300 rounded-md shadow-sm custom-focus sm:text-sm"
                        />
                        <div
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                        >
                        <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
                        </div>
                    </div>
                </div>

            <div className="mt-4">
              <button
                type="button"
                className="bouton_vert_grafana"
                onClick={handleImportOrgName}
              >
                Importer le nom de l'organisation
              </button>
              <button
                type="button"
                className={`bouton_vert_grafana ml-2 ${isValidateUrlDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleValidateUrl}
                disabled={isValidateUrlDisabled}
              >
                Valider l'URL
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GrafanaConfigForm;