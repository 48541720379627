import React, { useState } from 'react';
import axios from 'axios';
import '../css/bouton.css';

const AddDocument = ({ projectId, detailId, onUploadSuccess, onClose, fetchProjectDetails }) => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB size limit

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
            setError('Le fichier est trop volumineux. La taille maximale est de 100 Mo.');
            setFile(null);
            return;
        }

        setFile(selectedFile);
        setError(''); // Clear error message when user selects a new file
        setSuccessMessage(''); // Clear success message when user selects a new file
    };

    const handleUpload = async () => {
        if (!file) {
            setError("Veuillez sélectionner un fichier à uploader.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        setUploading(true);
        setError('');

        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            };
            const apiBaseUrl = process.env.REACT_APP_API_URL || '';
            const response = await axios.post(`${apiBaseUrl}/projets/${projectId}/details/${detailId}/documents/upload`, formData, config);

            setUploading(false);
            if (response.data) {
                onUploadSuccess(response.data, detailId); // Appel de la fonction pour mettre à jour l'état
                if (typeof fetchProjectDetails === 'function') {
                    await fetchProjectDetails();  // Récupérer à nouveau les détails du projet après l'upload
                }
                setFile(null); // Reset the file input after successful upload
                onClose(); // Close the modal after a successful upload
            } else {
                setError("Une réponse inattendue a été reçue du serveur.");
            }
        } catch (error) {
            console.error('Erreur lors de l\'upload du fichier:', error);
            setError(`Erreur lors de l'upload du fichier: ${error.message}`);
            setUploading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto bg-white p-6 rounded-lg max-w-3xl">
            <form onSubmit={(e) => { e.preventDefault(); handleUpload(); }} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Document:</label>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        aria-label="Sélectionner un fichier à télécharger (max 100 Mo)"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <button
                    type="submit"
                    disabled={uploading}
                    aria-busy={uploading}
                    aria-label={uploading ? 'Chargement en cours' : 'Uploader le fichier'}
                    className={`bouton_vert ${uploading ? 'bg-gray-500' : ''}`}
                >
                    {uploading ? 'Chargement...' : 'Uploader'}
                </button>
            </form>
            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4" role="alert">{error}</div>}
            {successMessage && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4" role="status">{successMessage}</div>}
        </div>
    );
};

export default AddDocument;
