import React, { useState } from 'react';
import UserProfileDetails from './UserProfileDetails';
import UserAccounts from './UserAccounts';

const UserProfile = () => {
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedTab, setSelectedTab] = useState('profil');

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setError('');
    setSuccessMessage('');
  };

  return (
    <div className="flex-grow" style={{ backgroundColor: '#F5F5F5' }}>
      <div className="w-full pt-6" style={{ height: '25vh', backgroundColor: '#166534' }}>
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
          <div className="bg-white p-6 shadow rounded-lg mt-8">
            <div className="pb-4">
              <h1 className="text-2xl font-bold">Paramètres Utilisateur</h1>
            </div>
            <div className="border-b border-gray-300 my-4">
              <nav className="flex space-x-4">
                <button
                  className={`py-2 px-4 ${selectedTab === 'profil' ? 'border-b-2 border-blue-500' : ''}`}
                  onClick={() => handleTabChange('profil')}
                >
                  Profil
                </button>
                <button
                  className={`py-2 px-4 ${selectedTab === 'authentification' ? 'border-b-2 border-blue-500' : ''}`}
                  onClick={() => handleTabChange('authentification')}
                >
                  Authentification
                </button>
              </nav>
            </div>
            <div className="flex-grow p-6 bg-white">
              <div className="max-w-4xl mx-auto bg-white p-6 shadow rounded-lg">
                {selectedTab === 'profil' && (
                  <UserProfileDetails
                    setError={setError}
                    setSuccessMessage={setSuccessMessage}
                    error={error}
                    successMessage={successMessage}
                  />
                )}
                {selectedTab === 'authentification' && (
                  <UserAccounts
                    setError={setError}
                    setSuccessMessage={setSuccessMessage}
                    error={error}
                    successMessage={successMessage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
