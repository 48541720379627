import React from 'react';

const VersionDisplay = () => {
  const releaseVersion = process.env.REACT_APP_RELEASE_VERSION || '1.0.0'; // Valeur par défaut si non définie

  return (
    <div className="fixed bottom-0 left-0 p-2 text-sm text-white">
      <p>Version: {releaseVersion}</p>
      <p>Bienvenue sur la version bêta du portail de projet.</p>
    </div>
  );
};

export default VersionDisplay;
