// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .form-label {
      display: inline-block;
      position: relative;
    }
  
  .form-label .required {
    color: red;
    position: absolute;
    right: -10px;
  }
  
  .form-label .optional {
    color: gray;
    position: absolute;
    right: -10px;
}`, "",{"version":3,"sources":["webpack://./src/css/formulaire.css"],"names":[],"mappings":"EAAE;MACI,qBAAqB;MACrB,kBAAkB;IACpB;;EAEF;IACE,UAAU;IACV,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":["  .form-label {\n      display: inline-block;\n      position: relative;\n    }\n  \n  .form-label .required {\n    color: red;\n    position: absolute;\n    right: -10px;\n  }\n  \n  .form-label .optional {\n    color: gray;\n    position: absolute;\n    right: -10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
