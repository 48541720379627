// Modal.js
import React from 'react';

const Modal = ({ isOpen, onClose, children, sizeClass = "max-w-3xl" }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-40 flex items-center justify-center p-4">
      <div className={`bg-white rounded-lg shadow-xl relative p-6 w-full ${sizeClass}`}>
        <button
          onClick={onClose}
          className="absolute top-1 right-3 text-3xl font-semibold text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
