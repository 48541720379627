import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import AddProjectDetail from './AddProjectDetail';
import Modal from './Modal';
import ProjectDetailsTableau from './ProjectDetailsTableau';
import ProjectDetailsTableauDocument from './ProjectDetailsTableauDocument';

const ProjectDetails = () => {
  const [project, setProject] = useState({});
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const [isAddDetailModalOpen, setIsAddDetailModalOpen] = useState(false);
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryDoc, setSearchQueryDoc] = useState('');
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [selectedDetailId, setSelectedDetailId] = useState(null);

  const toggleAddDetailModal = () => {
    setIsAddDetailModalOpen(!isAddDetailModalOpen);
  };

  const toggleDocumentsModal = (detailId) => {
    setSelectedDetailId(detailId);
    setIsDocumentsModalOpen(!isDocumentsModalOpen);

    // Appeler fetchDocuments seulement lors de l'ouverture du modal
    if (!isDocumentsModalOpen) {
        fetchDocuments(detailId);  // Récupérer les documents associés à ce détail de projet
    }
};


  const handleMouseEnter = (index) => {
    setHoveredColumn(index);
  };

  const handleMouseLeave = () => {
    setHoveredColumn(null);
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return '';
    const [year, month, day] = isoDate.split('T')[0].split('-');
    return `${day}-${month}-${year}`;
  };

  const fetchDocuments = useCallback(async (detailId) => {
    try {
        const token = localStorage.getItem('token');
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        const response = await axios.get(`${apiBaseUrl}/projets/${id}/details/${detailId}/documents`, config);
        return response.data || [];
    } catch (error) {
        if (error.response && error.response.status === 404) {
            console.warn('Aucun document trouvé pour ce détail de projet.');
            return [];  // Retourner un tableau vide si aucun document n'est trouvé
        }
        console.error('Erreur lors de la récupération des documents:', error);
        return [];  // Empêche l'application de planter
    }
  }, [id]);

  const fetchProjectDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const config = { headers: { Authorization: `Bearer ${token}` } };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
      const projectResponse = await axios.get(`${apiBaseUrl}/projets/${id}`, config);
      const detailsResponse = await axios.get(`${apiBaseUrl}/projets/${id}/details`, config);

      const detailsWithDocuments = await Promise.all(detailsResponse.data.map(async (detail) => {
        const documents = await fetchDocuments(detail.id);
        return { ...detail, documents };
      }));

      setProject(projectResponse.data);
      setDetails(detailsWithDocuments);
      setLoading(false);
    } catch (error) {
      setError('Impossible de charger les détails du projet.');
      setLoading(false);
    }
  }, [id, fetchDocuments]);

  useEffect(() => {
    fetchProjectDetails();  // Charger les détails du projet dès que le composant est monté
}, [fetchProjectDetails]);

  const handleDownload = async (detailId, docId, nomDocument) => {
    try {
      const token = localStorage.getItem('token');
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';
  
      // Utilisation du detailId et docId correctement dans l'URL
      const downloadUrl = `${apiBaseUrl}/projets/${id}/details/${detailId}/documents/${docId}/download`;
  
      const config = {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        responseType: 'blob',  // Important pour gérer le téléchargement d'un fichier
      };
  
      const response = await axios.get(downloadUrl, config);
  
      const contentType = response.headers['content-type'];
      const blobUrl = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
  
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', nomDocument);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erreur lors du téléchargement du document:', error);
    }
  };
  
  
  const handleDeleteDetail = async (detailId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce détail du projet ?')) {
      try {
        const token = localStorage.getItem('token');
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        await axios.delete(`${apiBaseUrl}/projets/${id}/details/${detailId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        fetchProjectDetails();
      } catch (error) {
        console.error('Erreur lors de la suppression du détail:', error);
        setError('Erreur lors de la suppression du détail.');
      }
    }
  };

  const handleDeleteDocument = async (detailId, docId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce document ?')) {
      try {
        const token = localStorage.getItem('token');
        const apiBaseUrl = process.env.REACT_APP_API_URL || '';
        const url = `${apiBaseUrl}/projets/${id}/details/${detailId}/documents/${docId}`;
        await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } });
        fetchProjectDetails();
      } catch (error) {
        console.error('Erreur lors de la suppression du document:', error);
        setError('Erreur lors de la suppression du document.');
      }
    }
  };

  const openEditModal = (document) => {
    setCurrentDocument(document);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const onEditSuccess = (updatedDocument) => {
    setDetails(details.map(detail => {
      return detail.id === updatedDocument.ID_DetailProjet ? {
        ...detail,
        documents: detail.documents.map(doc => {
          if (doc.id === updatedDocument.id) {
            return updatedDocument;
          }
          return doc;
        })
      } : detail;
    }));
    fetchProjectDetails();
    closeEditModal();
  };

  if (loading) return <div>Chargement en cours...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="flex flex-row min-h-screen relative">
      <div className="flex-grow" style={{ backgroundColor: '#F5F5F5' }}>
        <div className="w-full pt-6" style={{ height: '25vh', backgroundColor: '#166534' }}>
          <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
            <div className="bg-white p-6 shadow rounded-lg mt-8 relative">
              <i
                className="fas fa-arrow-left absolute top-4 left-5 text-2xl cursor-pointer"
                onClick={() => navigate(-1)}
                style={{ color: '#202244' }}
              ></i>
              <h1 className="text-2xl font-bold text-gray-800 mt-6">
                Projet : {project.NomDuProjet}
              </h1>
              <h1 className="text-1xl font-semi-bold text-gray-800 mb-6">
                Proprietaire : {project.prenomProprietaire} {project.nomProprietaire}
              </h1>
              {error && <div className="bg-red-500 text-white p-2 rounded">{error}</div>}
              <ProjectDetailsTableau
                details={details}
                projectId={id}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                fetchProjectDetails={fetchProjectDetails}
                toggleAddDetailModal={toggleAddDetailModal}
                toggleDocumentsModal={toggleDocumentsModal}
                handleDeleteDetail={handleDeleteDetail}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hoveredColumn={hoveredColumn}
                formatDate={formatDate}
              />
              <Modal isOpen={isAddDetailModalOpen} onClose={toggleAddDetailModal}>
                <AddProjectDetail
                  onAddSuccess={() => {
                    fetchProjectDetails();
                    toggleAddDetailModal();
                  }}
                  projectName={project.NomDuProjet}
                />
              </Modal>
              <Modal isOpen={isDocumentsModalOpen} onClose={() => setIsDocumentsModalOpen(false)} sizeClass="max-w-5xl">
                <ProjectDetailsTableauDocument
                  project={project}
                  details={details}
                  searchQueryDoc={searchQueryDoc}
                  setSearchQueryDoc={setSearchQueryDoc}
                  handleDownload={handleDownload}
                  handleDeleteDocument={handleDeleteDocument}
                  isEditModalOpen={isEditModalOpen}
                  currentDocument={currentDocument}
                  openEditModal={openEditModal}
                  closeEditModal={closeEditModal}
                  onEditSuccess={onEditSuccess}
                  selectedDetailId={selectedDetailId}
                  formatDate={formatDate}
                  fetchProjectDetails={fetchProjectDetails} // Passer cette fonction pour rafraîchir les détails après ajout de document
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProjectDetails);
