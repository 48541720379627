import { useMemo, useState } from 'react';

// Hook pour gérer la configuration du tri
export const useSortConfig = (initialKey = '', initialDirection = '') => {
  const [sortConfig, setSortConfig] = useState({ key: initialKey, direction: initialDirection });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    if (key === 'Proprietaire') {
      // Spécifier la logique pour trier sur le prénom et le nom combinés
      setSortConfig({
        key: 'Proprietaire',
        direction,
        sortFunction: (a, b) => {
          const fullNameA = `${a.prenomProprietaire} ${a.nomProprietaire}`.toLowerCase();
          const fullNameB = `${b.prenomProprietaire} ${b.nomProprietaire}`.toLowerCase();
          if (fullNameA < fullNameB) return direction === 'ascending' ? -1 : 1;
          if (fullNameA > fullNameB) return direction === 'ascending' ? 1 : -1;
          return 0;
        }
      });
    } else {
      // Pour les autres colonnes
      setSortConfig({ key, direction });
    }
  };  

  return { sortConfig, requestSort };
};

// Hook pour trier les données
export const useSortedData = (data, sortConfig) => {
  const sortedData = useMemo(() => {
    if (!sortConfig || !sortConfig.key) return data;

    return [...data].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      if (sortConfig.key === 'Campagne') {
        aValue = new Date(a.Campagne);
        bValue = new Date(b.Campagne);
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue, undefined, { sensitivity: 'base' }) * (sortConfig.direction === 'ascending' ? 1 : -1);
      }

      if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;

      return 0;
    });
  }, [data, sortConfig]);

  return sortedData;
};

// Fonction pour générer l'icône de tri
// Fonction pour générer l'icône de tri
export const getSortIcon = (key, sortConfig) => {
  const isActive = sortConfig.key === key;
  const isAscending = sortConfig.direction === 'ascending';

  return (
    <span className="sort-icons">
      {/* Flèche ascendante */}
      <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 0L0 6H10L5 0Z" fill={isActive && isAscending ? '#000000' : '#CCCCCC'} />
      </svg>
      {/* Flèche descendante */}
      <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 6L0 0H10L5 6Z" fill={isActive && !isAscending ? '#000000' : '#CCCCCC'} />
      </svg>
    </span>
  );
};
