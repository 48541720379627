import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useCampaigns } from '../CampaignContext';
import '../css/bouton.css';
import '../css/formulaire.css';

const AddProjectDetail = ({ onAddSuccess }) => {
  const [detailData, setDetailData] = useState({
    Campagne: '',
    Libelle: '',
    Commentaire: '',
  });
  const [message, setMessage] = useState('');
  const { id } = useParams(); // ID du projet
  const { addCampaign } = useCampaigns();

  const handleChange = (e) => {
    setDetailData({ ...detailData, [e.target.name]: e.target.value });
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const apiBaseUrl = process.env.REACT_APP_API_URL || '';  
      const response = await axios.post(`${apiBaseUrl}/projets/${id}/details`, detailData, config);
      const newCampaign = response.data;
      setMessage('Détail ajouté avec succès.');
      setDetailData({ Campagne: '', Libelle: '', Commentaire: '' });
      addCampaign(newCampaign);
      if (onAddSuccess) {
        onAddSuccess();
      }
    } catch (error) {
      console.error('Erreur lors de l ajout du détail du projet:', error);
      setMessage('Erreur lors de l ajout du détail. Veuillez réessayer.');
    }
  };

  return (
    <div className="max-w-md mx-auto my-2 bg-white rounded max-w-2xl">
      <h2 className="text-lg font-semibold text-gray-800 mb-4">Nouvelle entrée de campagne</h2>
      <p className="text-sm text-gray-500 text-right"><span className="text-red-500">*</span> : Champs  obligatoires</p>
      {message && (
        <div className={message.includes('Erreur') ? "bg-red-500 text-white p-2 rounded" : "bg-green-500 text-white p-2 rounded"}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Campagne (Date)<span className="required" title="Ce champ est obligatoire">*</span></label>
          <input 
            type="date" 
            name="Campagne" 
            value={detailData.Campagne} 
            onChange={handleChange} 
            placeholder="Sélectionnez une date de campagne"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 form-label">Libellé<span className="required" title="Ce champ est obligatoire">*</span></label>
          <input 
            type="text" 
            name="Libelle" 
            value={detailData.Libelle} 
            onChange={handleChange} 
            placeholder="Entrez le libellé de la campagne"
            required 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Commentaire</label>
          <textarea 
            name="Commentaire" 
            value={detailData.Commentaire} 
            onChange={handleChange} 
            placeholder="Ajoutez un commentaire (facultatif)"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          ></textarea>
        </div>
        <div className="flex justify-between">
          <button type="submit" className="bouton_vert">Ajouter une campagne</button>
        </div>
      </form>
    </div>
  );
};

export default AddProjectDetail;
