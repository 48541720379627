// useMessageHandler.js
import { useState, useCallback } from 'react';

export const useMessageHandler = () => {
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const clearMessagesAfterTimeout = useCallback(() => {
    setTimeout(() => {
      setError('');
      setSuccessMessage('');
    }, 5000);
  }, []);

  return {
    error,
    successMessage,
    setError,
    setSuccessMessage,
    clearMessagesAfterTimeout,
  };
};
