// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.underline-link {
    text-decoration: underline;
  }
  

/* Appliquer une mise en page fixe pour la table */
table {
  table-layout: fixed;
  width: 100%;
}

/* Ajustement pour les colonnes */
th, td {
  width: 25%; /* ou ajuster en fonction du nombre de colonnes */
  text-align: left;
}

.sort-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10%;
  margin-top: 0.3125rem;
}

/* Rendre le tableau défilable sur petit écran */
@media (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  th, td {
    width: auto; /* S'adapte au contenu */
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/tableau.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B;;;AAGF,kDAAkD;AAClD;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA,iCAAiC;AACjC;EACE,UAAU,EAAE,iDAAiD;EAC7D,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;EACR,qBAAqB;AACvB;;AAEA,gDAAgD;AAChD;EACE;IACE,cAAc;IACd,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,WAAW,EAAE,wBAAwB;EACvC;AACF","sourcesContent":[".underline-link {\n    text-decoration: underline;\n  }\n  \n\n/* Appliquer une mise en page fixe pour la table */\ntable {\n  table-layout: fixed;\n  width: 100%;\n}\n\n/* Ajustement pour les colonnes */\nth, td {\n  width: 25%; /* ou ajuster en fonction du nombre de colonnes */\n  text-align: left;\n}\n\n.sort-icons {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10%;\n  margin-top: 0.3125rem;\n}\n\n/* Rendre le tableau défilable sur petit écran */\n@media (max-width: 768px) {\n  table {\n    display: block;\n    overflow-x: auto;\n    white-space: nowrap;\n  }\n  \n  th, td {\n    width: auto; /* S'adapte au contenu */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
