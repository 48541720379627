import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Register = () => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    motDePasse: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiBaseUrl = process.env.REACT_APP_API_URL || ''; 
      await axios.post(`${apiBaseUrl}/users/register`, { ...formData, role: 'USER' });
      navigate('/home');
    } catch (error) {
      console.error('Erreur lors de l\'inscription:', error);
    }
  };


  
  return (
    <div>
      <h2>Inscription</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nom:</label>
          <input type="text" name="nom" onChange={handleChange} required />
        </div>
        <div>
          <label>Prénom:</label>
          <input type="text" name="prenom" onChange={handleChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" onChange={handleChange} required />
        </div>
        <div>
          <label>Mot de Passe:</label>
          <input type="password" name="motDePasse" onChange={handleChange} required />
        </div>
        <button type="submit">S'inscrire</button>
        <p>Déjà inscrit? <Link to="/">Connectez-vous ici</Link></p>
      </form>
    </div>
  );
};

export default Register;
